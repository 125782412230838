<template>
  <div class="associationDetail">
    <div class="associationDetail-body"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {} from "./api.js";

export default {
  name: "associationDetail",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    // this.getIsApply();
  },
  methods: {
    // 加载列表数据
    onLoad() {},
    onRefresh() {
      this.curPage = 1;
      // this.listData = [];
      this.onLoad();
    },
  },
};
</script>

<style lang="less" scoped>
.associationDetail {
  background: #007eff;
}
</style>
