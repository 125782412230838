//获取社群列表
const getAssociaListUrl = `/accApi/accPoints/association/getList`;

//获取社群分类
const getAssociaKindsListUrl = `/accApi/accPoints/association/getInitInfo`;
//删除社群分类
const getDeleteListUrl = `/accApi/accPoints/association/delete`;
// 新增社群分类
const getKindsUrl = `/accApi/accPoints/association/add`;
//  更新社群分类
const updateKindsUrl = `/accApi/accPoints/association/update`;
//  获取社群分类详情
const getKindsDetailUrl = `/accApi/accPoints/association/getDetailInfoById`;
export {
  getAssociaListUrl,
  getAssociaKindsListUrl,
  getDeleteListUrl,
  getKindsUrl,
  getKindsDetailUrl,
  updateKindsUrl,
};
